* {
  font-family: "Baskervville", serif;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  color: #211614;
}

html,
body,
#root {
  height: 100%;
}
.App {
  text-align: center;
  background-color: #211614;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {

  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  font-family: "Beth Ellen", cursive;
  font-size: 22px;
  color: inherit;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}